.footer {
  background-color: var(--main-red);
  font-size: 1rem;
  opacity: 0.8;
  min-height: 100px;
  padding-left: 1rem;
}

.copyright {
  font-size: 0.6rem;
  color: var(--subtext-grey);
}

.copyright a {
  color: var(--subtext-grey);
}

@media only screen and (max-width: 600px) {
  .footer {
    font-size: 0.9rem;
    padding-left: 0.4rem;
  }
}
