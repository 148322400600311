@font-face {
  font-family: 'Open Sans';
  src: url('../public/OpenSans-VariableFont_wdth,wght.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Lobster';
  src: url('../public/Lobster-Regular.ttf');
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(22, 22, 22);
  color: #fff;
  font-size: 1rem;
}

body,
html {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-red: #a71717;
  --background-gradient: linear-gradient(
    rgb(0, 0, 0, 0),
    rgba(74, 73, 73, 0.8)
  );
  --subtext-grey: rgb(164, 164, 164);
}

@media only screen and (max-width: 800px) {
  @-moz-document url-prefix() {
    body {
      font-size: 0.93rem;
    }
  }
}
