.gallery-display {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.gallery-image {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 850px) {
  .gallery-display {
    grid-template-columns: repeat(1, 1fr);
  }
}
