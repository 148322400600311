.image-container {
  max-width: 30%;
  max-height: 30%;
  align-items: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  position: relative;
}

.img {
  width: 100%;
  height: auto;
  opacity: 1;
  display: block;
  transition: 0.3s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.3s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
}

.image-container:hover .img {
  opacity: 0.3;
}

.image-container:hover .middle {
  opacity: 1;
}

.image-container:hover {
  transform: scale(1.02);
  transition: 0.3s ease;
}

.small-screen-text {
  color: var(--subtext-grey);
}

.text {
  color: white;
  font-size: 2rem;
}

#registry {
  margin-left: 3rem;
  margin-right: 3rem;
}

@media only screen and (max-width: 600px) {
  .image-container {
    max-width: 100%;
    max-height: 100%;
  }

  #registry {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
