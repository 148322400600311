.countdown-container {
  overflow: hidden;
  background: linear-gradient(rgb(0, 0, 0, 0.4), rgba(45, 44, 44, 0.8)),
    url('/public/DSC_0066.webp');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-width: auto;
  height: 500px;
  position: relative;
}

.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expired-notice > span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expired-notice > p {
  font-size: 1.5rem;
}

.show-counter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #ebebeb;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}

.countdown-title {
  text-align: center;
  position: relative;
  top: 25%;
  left: 0;
  right: 0;
  margin: auto;
  color: white;
  text-transform: uppercase;
}

.content {
  position: absolute;
  top: 25%;
  right: 50%;
  text-align: center;
  transform: translate(50%, 50%);
}

.countdown-title {
  text-transform: uppercase;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 600px) {
  .countdown-container {
    background-size: cover;
    max-width: auto;
    height: 50vh;
  }

  .content {
    top: 0;
  }
}

@media only screen and (max-width: 800px) {
  /* Firefox treats view height different from any other browser, 
  so need to transform content to adjust */
  @-moz-document url-prefix() {
    .content {
      transform: translate(50%, 2%) scale(0.9);
    }
  }
}
