.header {
  height: 850px;
  width: 100%;
  text-align: center;
  padding-top: 100px;
  color: white;
  overflow: hidden;
  background: var(--background-gradient), url('/public/header-img.webp');
  background-size: cover;
  background-repeat: no-repeat;
}

.title {
  text-transform: uppercase;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
}

.center {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-50%);
}

.rsvp-button {
  width: 15rem;
  background-color: var(--main-red);
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.rsvp-button:hover {
  background-color: #000;
}
