.expand-icon {
  cursor: pointer;
}

.faq-card {
  position: relative;
  background-color: rgb(39, 35, 35);
  border: 3px solid rgb(31, 30, 30);
}

.faq-answer {
  margin: 5px;
}

.faq-question {
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
