#faq-page {
  margin: 4rem 2rem 2rem 2rem;
}

.link {
  color: white;
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.link:hover {
  color: var(--main-red);
}

.iframe {
  width: 40%;
  height: 18.75rem;
}

@media only screen and (max-width: 600px) {
  #faq-page {
    margin-left: 5px;
    margin-right: 5px;
  }

  .iframe {
    width: 100%;
  }
}
