.nav-item {
  margin-right: 4rem;
  float: right;
  margin-top: 0.5rem;
  color: #fff;
  text-decoration: none;
  padding-top: 0.5rem;
}

.nav-item:hover {
  text-decoration: underline;
  color: var(--main-red);
}

.nav-title {
  float: left;
  margin-top: 0.5rem;
  text-transform: uppercase;
  margin-left: 4rem;
  padding-top: 0.5rem;
  color: white;
  text-decoration: none;
}

.navbar.colorChange {
  background: rgb(
    8,
    8,
    8
  ); /* Fallback for older browsers without RGBA-support */
  background: rgba(8, 8, 8, 0.7);
  transition: 0.8s all ease;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  height: 3rem;
  font-size: 1.23rem;
  font-family: 'Lusitana', Georgia, serif;
  transition: 0.8s all ease;
}

/* Collapsed Nav */
.collapsed-nav {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: rgb(22, 22, 22);
  overflow: hidden;
}

.collapsed-nav-item {
  color: white;
  font-size: 22px;
  font-weight: 400;
  font-family: 'Lusitana', Georgia, serif;
  text-decoration: none;
}

.collapsed-nav-hr {
  margin-right: 2rem;
  margin-top: 1rem;
}

ul {
  margin-top: 4rem;
}

li {
  list-style: none;
  margin-top: 1rem;
}

.close-btn {
  position: relative;
  float: right;
  color: red;
  height: 2rem;
  width: 2rem;
}

/* Mobile */

@media only screen and (max-width: 600px) {
  .nav-title {
    margin-left: 1rem;
  }

  .nav-item {
    margin-right: 1rem;
    text-decoration: underline;
  }

  .navbar {
    font-size: 1.15rem;
  }
}
