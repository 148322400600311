.quote-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-left: 10rem;
  margin-right: 10rem;
  place-items: center;
  margin-top: 1rem;
}

.quote-image {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 1000px) {
  .quote-container {
    margin-left: 5rem;
    margin-right: 5rem;
  }
}

@media only screen and (max-width: 850px) {
  .quote-container {
    grid-template-columns: repeat(1, 1fr);
    margin-left: auto;
    margin-right: auto;
  }
}
