.description {
  color: var(--subtext-grey);
}

.venmo-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.venmo-code:hover {
  transform: scale(1.02);
  transition: 0.3s ease;
}

.venmo-code {
  height: auto;
  width: 100%;
}

.honeymoon-fund {
  text-align: center;
  margin-left: 20rem;
  margin-right: 20rem;
}

@media only screen and (max-width: 1300px) {
  .honeymoon-fund {
    margin-left: 10rem;
    margin-right: 10rem;
  }
}

@media only screen and (max-width: 850px) {
  .venmo-info {
    grid-template-columns: repeat(1, 1fr);
  }

  .honeymoon-fund {
    margin-left: auto;
    margin-right: auto;
  }

  .description {
    margin-left: 5px;
    margin-right: 5px;
  }
}
